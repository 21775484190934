<template>
  <section>
    <b-row>
      <b-col md="4">
        <expired-certificates />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import isElectron from 'is-electron'
import { hasErpAccess } from '@/auth/utils'
import { BRow, BCol } from 'bootstrap-vue'
import ExpiredCertificates from './components/dashboard/ExpiredCertificates.vue'

export default {
  components: {
    ExpiredCertificates,
    BRow,
    BCol,
  },
  mounted() {
    if (isElectron() && !hasErpAccess()) {
      this.$router.push({ name: 'pdv-pay-box' })
    }
  },
}
</script>

<style></style>
